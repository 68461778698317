import React from 'react';
import About from '../../Pages/About';
import Upload from '../../Pages/Upload';
import SignIn from '../../Pages/SignIn';
import SignUp from '../../Pages/SignUp';
import SignOut from '../../Pages/SignOut';
import MainGrid from './MainGrid';
import Animals from '../../Pages/Animals';




enum DashboardPages {
    Home, Upload, About, Help, Account, Animals
}

export function getDashboardPageFromName(page: string): DashboardPages {
    switch (page) {
        case 'home':
            return DashboardPages.Home;
        case 'upload':
            return DashboardPages.Upload;
        case 'about':
            return DashboardPages.About;
        case 'animals':
            return DashboardPages.Animals;
        default:
            return DashboardPages.Home;
    }
}

export function getNameFromDashboardPage(page: DashboardPages) {
    switch (page) {
        case DashboardPages.Home:
            return 'home';
        case DashboardPages.Upload:
            return 'upload';
        case DashboardPages.About:
            return 'about';
        case DashboardPages.Animals:
            return 'animals';
    }
}

export function getPrettyNameFromDashboardPage(page: DashboardPages) {
    switch (page) {
        case DashboardPages.Home:
            return 'Home';
        case DashboardPages.Upload:
            return 'Upload';
        case DashboardPages.About:
            return 'About';
        case DashboardPages.Animals:
            return 'Animals';
    }
}

export function getDashboardPageFromPath(path: string): DashboardPages {
    const page = path.split('/').pop();
    if (page) {
        return getDashboardPageFromName(page);
    }
    return DashboardPages.Home;
}

export function getDashboardPageRenderFromDashboardPage(page: DashboardPages, alertQueue: React.ReactNode[], setAlertsQueue: any, onUploadSuccess?: () => void) {
    const handleUploadSuccess = onUploadSuccess ?? (() => {});
    switch (page) {
        case DashboardPages.Home:
            return <MainGrid />;
        case DashboardPages.Upload:
            return <Upload alertQueue={alertQueue} setAlertQueue={setAlertsQueue} onUploadSuccess={handleUploadSuccess}/>;
        case DashboardPages.About:
            return <About />;
        case DashboardPages.Animals:
            return <Animals />;
        default:
            return <MainGrid />;
    }
}

export interface DashboardMenuProps {
    currentDashboardPage: DashboardPages;
    setCurrentDashboardPage: any;
}

export default DashboardPages;